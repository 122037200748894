import React, { memo } from "react";

interface GameCardHeaderProps {
  columns: number;
}
const GameCardHeader = ({ columns = 5 }: GameCardHeaderProps) => {
  return (
    <>
      {"BINGO"
        .substring(0, columns)
        .split("")
        .map((letter) => {
          return (
            <div key={letter} className={`header-box header-box--${letter}`}>
              {letter}
            </div>
          );
        })}
    </>
  );
};

export default memo(GameCardHeader);
