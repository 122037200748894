import { css } from "@emotion/css";
import shuffle from "lodash/shuffle";
import DataHelper from "../../DataHelper";
import { randoIndex, rando } from "../../helpers";
import cloneDeep from "lodash/cloneDeep";

export const countBingos = (cards: ISquareRule[], grid: Array<number>) => {
  if (cards.length === 0) return 0;

  let bingos: number = 0;

  const [rows, cols] = grid;
  const board: ISquareRule[][] = [];
  let i = 0;

  //console.log(cards, grid);

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      if (col === 0) board[row] = [] as Array<ISquareRule>;

      board[row][col] = cards[i];

      i++;
    }
  }

  //console.log(board);

  for (let row = 0; row < board.length; row++) {
    let bingo = true;

    for (let col = 0; col < board[row].length; col++) {
      if (board[row][col] === undefined) {
        //console.log(row, col, board[row][col]);
      } else {
        if (!board[row][col].highlighted) {
          bingo = false;
        }
      }
    }

    if (bingo) {
      bingos++;
    }
  }

  for (let col = 0; col < cols; col++) {
    let bingo = true;

    for (let row = 0; row < rows; row++) {
      if (board[row][col] === undefined) {
        console.log(row, col, board[row][col]);
      } else {
        if (!board[row][col].highlighted) {
          bingo = false;
        }
      }
    }

    if (bingo) {
      bingos++;
    }
  }

  return bingos;
};

export const mobileSizeClass = (
  columns: number,
  mobileWidth: number,
  boardSideLength: number
) => css`
  grid-template-columns: repeat(${columns}, 1fr);

  @media (max-width: ${mobileWidth}rem) {
    grid-template-rows: min-content repeat(
        ${columns},
        ${boardSideLength - 1}rem
      );
    width: ${mobileWidth}rem;
  }
`;

export const prepRuleForSquare = (r: IRule, gameIn: IGame): ISquareRule => {
  const { rule, drinks } = r;

  return {
    ruleKey: r.ruleKey,
    rule,
    drinks,
    game: gameIn,
  };
};

export const shuffleRules = (
  data: DataHelper,
  currentIndex: string
): Array<IRule> =>
  shuffle(data.rulesObjectToArray(data.getGameRules(currentIndex, false)));

export const getGamesRules = (
  parents: IGame[],
  data: DataHelper
): IParentRules => {
  const parentGames: IParentRules = {};

  parents.forEach((parent) => {
    parentGames[parent.gameKey] = data.rulesObjectToArray(parent.rules);
  });

  return parentGames;
};

export const getWeightedGameKeys = (
  weightedGames: ISettingsParent[],
  parentGames: IParentRules
): string[] => {
  let weightedGameIndexes: string[] = [];

  weightedGames
    .filter((p) => p.ratio > 0) //ignore games with a ratio of 0
    .forEach((p, i) => {
      if (parentGames[p.key] && parentGames[p.key].length > 0)
        for (let r = 0; r < p.ratio; r++) {
          //debugger;
          weightedGameIndexes.push(p.key);
        }
    });

  return weightedGameIndexes;
};

export const getWeightedRandomRules = (
  numGames: number,
  totalSpacesToFill: number,
  weightedGameIndexes: string[],
  parentGames: IParentRules,
  data: DataHelper,
  duplicates: ISquareRule[] = []
): ISquareRule[] => {
  let rules: ISquareRule[] = [];

  parentGames = cloneDeep(parentGames);

  for (let r = 0; r < Math.min(numGames, totalSpacesToFill); r++) {
    //get a random key from the indexes array
    const parentKey = rando(weightedGameIndexes);

    //get the rules for that key
    let parentRules = parentGames[parentKey];

    if (parentRules === undefined) continue;

    if (duplicates.length) {
      //remove any rules that are already on the board
      parentRules = parentRules.filter(
        (pr) => duplicates.findIndex((dup) => pr.ruleKey === dup.ruleKey) === -1
      );
    }

    //pick a random rule
    const ruleIndex = randoIndex(parentRules);
    const rule = parentRules[ruleIndex];

    //remove that rule from the array so we dont pick it again
    parentGames[parentKey] = [
      ...parentGames[parentKey].slice(0, ruleIndex),
      ...parentGames[parentKey].slice(ruleIndex + 1),
    ];

    //if that was the last rule in that game, remove its key from contention
    if (parentGames[parentKey].length == 0) {
      weightedGameIndexes = weightedGameIndexes.filter(
        (key) => key !== parentKey
      );
    }

    //add the rule to the rules array, including its game
    rules.push({
      ...rule,
      game: data.getGameByKey(parentKey),
    });
  }

  return rules;
};

export const placeFreeSquare = (grid: number[], rules: ISquareRule[]) => {
  const [rows, cols] = grid;

  const freeSquareRule: ISquareRule = {
    id: "free",
    rule: null,
    highlighted: true,
  };

  //if both row and column are odd, put the free square in the center
  if (rows % 2 !== 0 && cols % 2 !== 0) {
    const freeIndex = (rows * cols - 1) / 2;

    rules = [
      ...rules.slice(0, freeIndex),
      freeSquareRule,
      ...rules.slice(freeIndex + 1),
    ];
  } else {
    //otherwise it floats free
    rules.push(freeSquareRule);
    rules = shuffle(rules);
  }

  return rules;
};

export const cardContainsRule = (
  rule: ISquareRule,
  cardRules: ISquareRule[]
): boolean => {
  return (
    cardRules.find((r) => {
      return r.ruleKey === r.ruleKey;
    }) !== undefined
  );
};

export const isFreeSquare = (rule: ISquareRule) => rule.id === "free";

export const getCardRuleIndexByKey = (key: string, cardRules: ISquareRule[]) =>
  cardRules.findIndex((cr) => cr.ruleKey === key);

// export const animationsEnabled = () => {
//   let animation = false,
//     animationstring = "animation",
//     keyframeprefix = "",
//     domPrefixes: Array<string> = "Webkit Moz O ms Khtml".split(" "),
//     pfx = "",
//     elem: HTMLElement = document.createElement("div");

//   if (elem.style.animationName !== undefined) {
//     animation = true;
//   }

//   if (animation === false) {
//     for (let i: number = 0; i < domPrefixes.length; i++) {
//       //@ts-ignore
//       if (elem.style[`${domPrefixes[i]}AnimationName`] !== undefined) {
//         pfx = domPrefixes[i];
//         animationstring = pfx + "Animation";
//         keyframeprefix = "-" + pfx.toLowerCase() + "-";
//         animation = true;
//         break;
//       }
//     }
//   }

//   return animation;
// };

export const onAnimationsEnabled = (cb: Function) => {
  const div = document.createElement("div");
  div.classList.add("animation-test");

  document.body.appendChild(div);

  div.addEventListener("animationstart", () => {
    cb();
  });
};

export const getHighlightedSquareIndexes = (
  cardRules: ISquareRule[],
  includeFreeSquare: boolean = false
): number[] => {
  const toRet: number[] = [];

  cardRules.forEach((rule, i) => {
    if (rule.highlighted && (includeFreeSquare || rule.id !== "free")) {
      toRet.push(i);
    }
  });

  return toRet;
};
