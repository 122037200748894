import React, { memo } from "react";

interface FreeSquareProps {
  title?: string;
  subtitle?: string;
  onCogClick?: any;
  reRolls?: number;
  onGroupClick?: any;
  groupToken?: string | null;
  shakeSubTitle?: boolean;
  onHelpClick?: (e: any) => void; //fix the event type eventually
}

const FreeSquare = ({
  title,
  subtitle,
  onCogClick,
  reRolls = 0,
  onGroupClick,
  groupToken,
  shakeSubTitle = false,
  onHelpClick = (e) => {},
}: FreeSquareProps) => {
  return (
    <div className="GameCardFreeSquare">
      <h1>{title}</h1>
      <h2 className={`${shakeSubTitle ? "shake" : ""}`}>{subtitle}</h2>
      <div className={`rule-box__game-title`}>(Free Square)</div>

      <div className="GameCardFreeSquare__controls">
        <button className="GameCartFreeSquare__help" onClick={onHelpClick}>
          <i className="fa fa-question"></i>
        </button>

        <button
          className="GameCardFreeSquare__settings-btn"
          onClick={onCogClick}
        >
          <i className="fa fa-cog"></i>
        </button>
        <div>
          <span className="GameCardFreeSquare__group-token">{groupToken}</span>
          <button
            className="GameCardFreeSquare__join-group"
            onClick={onGroupClick}
          >
            <i className="fa fa-users"></i>
          </button>
        </div>
        <div className="GameCardFreeSquare__re-rolls">
          <i className="fa fa-refresh"></i>
          <span className="GameCardFreeSquare__re-rolls-val">{reRolls}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(FreeSquare);
