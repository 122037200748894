import React from "react";

interface RuleBoxProps {
  rule: ISquareRule;
  children?: any;
  onClick: (e: any) => void;
}
const RuleBox = ({ rule, children, onClick }: RuleBoxProps) => {
  return (
    <div
      className={`rule-box rule-box--${rule.id || rule.ruleKey}${
        rule.highlighted ? " rule-box--highlighted" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default RuleBox;
