import React from 'react';

class NotFound extends React.Component
{
    render()
    {
        return (
            <div className="NotFound">
                <h1>Page Not Found</h1>
            </div>);
    }
}

export default NotFound;