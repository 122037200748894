import React, { useRef } from "react";
import FieldSet from "./FieldSet";
import Modal from "./Modal";

const GroupModal = ({
  open = false,
  onClose = () => {},
  onAction = (name, gameToke) => {},
}) => {
  const nameField = useRef(null);
  const tokenField = useRef(null);

  return (
    <div className="GroupModal">
      <Modal
        open={open}
        title="Group Settings"
        showButton={false}
        onClose={onClose}
      >
        <FieldSet legend="Join or Start a Group" className="GroupModal__fields">
          <p>
            Enter your name.
            <br />
            Enter a Group Code to join a group, or click <strong>
              Start
            </strong>{" "}
            to start a group.
          </p>
          <input
            type="text"
            className="GroupModal__user-name"
            placeholder="Your Name"
            ref={nameField}
          />
          <input
            type="text"
            className="GroupModal__group-code"
            placeholder="Group Code"
            ref={tokenField}
            maxLength={4}
            data-tip="only required when joining an existing game"
            title="only required when joining an existing game"
          />
          <button
            className="GroupModal__start-join-group"
            onClick={(e) => {
              onAction(nameField.current.value, tokenField.current.value);
            }}
          >
            Start / Join
          </button>
        </FieldSet>
      </Modal>
    </div>
  );
};

export default GroupModal;
