import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import GameCardHeader from "./GameCardHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataHelper from "../../DataHelper";
import { getDefaultSettings, getSavedSettings } from "./settings";
import {
  getGamesRules,
  getWeightedRandomRules,
  getWeightedGameKeys,
  mobileSizeClass,
  prepRuleForSquare,
  shuffleRules,
  placeFreeSquare,
  isFreeSquare,
  cardContainsRule,
  getCardRuleIndexByKey,
  countBingos,
  getHighlightedSquareIndexes,
} from "./helpers";
import { setTitle, getRandomItems, rando } from "../../helpers";
import RuleSquare from "./RuleSquare";
import FreeSquare from "./FreeSquare";
import RuleBox from "./RuleBox";
import useGroupPlay from "./useGroupPlay";
import GroupModal from "../GroupModal";
import shuffle from "lodash/shuffle";
import GameCardSettingsModal from "./GameCardSettingsModal";
import Confetti from "./Confetti";
import { css } from "@emotion/css";

const GameCard = ({
  games,
  currentIndex,
  mobileBreak = 820,
  boardSideLength = 14,
  toastSettings = {
    desktopLimit: 3,
    mobileLimit: 1,
    mobileTTL: 5000,
    desktopTTL: 3000,
    pauseOnFocusLoss: false,
  },
}: IGameCardProps) => {
  document.body.classList.add("App--GameCard");

  const data = new DataHelper(games);

  const [cardRules, setCardRules] = useState<ISquareRule[]>([]);
  const _cardRules = useRef(cardRules);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [bingos, setBingos] = useState(0);
  const [bingoIndexes, setBingoIndexes] = useState<string[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [columnsCss, setColumnsCss] = useState<string>("");
  const curBingos = useRef(0);

  useEffect(() => {
    console.log(bingos);

    if (bingos > curBingos.current) {
      const newIndexes = getHighlightedSquareIndexes(cardRules, false).join("");

      if (!bingoIndexes.includes(newIndexes)) {
        setReRolls(Math.max(settings.grid[0], settings.grid[1]) * 2 + reRolls);

        setShowConfetti(true);

        setBingoIndexes((cur) => {
          return [...cur, newIndexes];
        });

        setTimeout(() => {
          setShowConfetti(false);
        }, 5500);
      }
    }

    curBingos.current = bingos;
  }, [bingos]);

  const onSettingsChange = (newSettings: ISettings) => {
    setSettings({ ...newSettings });

    console.log(newSettings);

    window.localStorage.setItem(currentIndex, JSON.stringify(newSettings));
  };

  //this is because the socket listener can only access the initial state
  //so it doesn't see cardRules changed by the user
  //we store a copy in a ref to use for checking when a group user highlights
  //a square the current user re-rolled
  useEffect(() => {
    _cardRules.current = cardRules;

    setBingos(countBingos(cardRules, settings.grid));
  }, [cardRules]);

  const [allRuleIndexes, setAllRuleIndexes] = useState<string[]>([]);

  const highlightRule = (i: number, force?: boolean) => {
    setCardRules((oldRules) => {
      let newVal;

      //force to whatever is passed as force var
      if (force !== undefined) {
        newVal = force;
      } else {
        newVal =
          //if this is the first time setting the var, set true
          oldRules[i].highlighted === undefined
            ? true
            : //else toggle
              !oldRules[i].highlighted;
      }

      return [
        ...oldRules.slice(0, i),
        {
          ...oldRules[i],
          highlighted: newVal,
        },
        ...oldRules.slice(i + 1),
      ];
    });
  };

  const {
    groupOpen,
    setGroupOpen,
    createGroup,
    joinGroup,
    groupToken,
    socket,
    broadcastHighlight,
    broadcastReRoll,
  } = useGroupPlay({
    bingos,
    data,
    cardRules,
    onGroupHighlight: useCallback(
      (ruleKey: string, force?: boolean) => {
        const newRule = getCardRuleIndexByKey(ruleKey, _cardRules.current);

        if (newRule > -1) highlightRule(newRule, force);
      },
      [cardRules]
    ),
  });

  const game: IGame = useMemo(
    () => data.getGameByKey(currentIndex),
    [currentIndex]
  );
  const parents: IGame[] = useMemo(() => data.getParentGames(game), [game]);

  const [settings, setSettings] = useState<ISettings>(
    getSavedSettings(currentIndex) || getDefaultSettings(parents)
  );

  const [gridSize, setGridSize] = useState(settings.grid[0] * settings.grid[1]);
  const [reRolls, setReRolls] = useState(gridSize - 1);

  /**
   * Force dimensions on mobile for dragging around, but still having
   * legibility
   **/
  const mobileWidth = settings.grid[0] * boardSideLength;

  const gameCardMobile = mobileSizeClass(
    settings.grid[0],
    mobileWidth,
    boardSideLength
  );

  let gameRules = useMemo(
    () =>
      shuffleRules(data, currentIndex).map((r) => prepRuleForSquare(r, game)),
    [game]
  );

  const parentGames = useMemo(
    () => getGamesRules(parents, data),
    [game, settings]
  );

  useEffect(() => {
    setGridSize(settings.grid[0] * settings.grid[1]);

    console.log(settings.grid);

    setColumnsCss(css`
      grid-template-columns: repeat(${settings.grid[1]}, 1fr) !important;
    `);

    setTitle(`${game.title} Drinking Bingo`);

    let ruleIndexes = getWeightedGameKeys(settings.parents, parentGames);

    const chosenGameRules =
      gameRules.length > gridSize / 2
        ? shuffle(gameRules).slice(0, Math.floor(gridSize / 2))
        : gameRules;

    const numparentGames = gridSize - chosenGameRules.length;

    let rules = [
      ...chosenGameRules,
      ...getWeightedRandomRules(
        numparentGames,
        gridSize,
        ruleIndexes,
        parentGames,
        data
      ),
    ];

    //fill the board with repeats if there aren't enough rules
    if (rules.length < gridSize - 1) {
      const repeats = getRandomItems(rules, gridSize - rules.length - 1);

      rules = [...rules, ...repeats];

      //no re-rolls allowed if there weren't enough rules to fill the grid
      setReRolls(0);
    }

    const newRules = placeFreeSquare(settings.grid, shuffle(rules));

    setCardRules(newRules);

    setAllRuleIndexes([...ruleIndexes, currentIndex]);
  }, [currentIndex, settings]);

  const onRuleSquareClick = (rule: ISquareRule, i: number) => {
    highlightRule(i);
    //if (socket !== null) broadcastHighlight(i);
  };

  /**
   *
   * @param {*} index
   * @returns
   */
  const reRoll = (
    index: number,
    gridSize: number,
    rrChange: number = -1
  ): void => {
    const oldSquare = cardRules[index];
    const newRuleGameIndex = rando(allRuleIndexes);
    //const newGame = data.getGameByKey(newRuleGameIndex);

    const newRule = rando(
      getWeightedRandomRules(
        Object.keys(parentGames).length,
        1,
        allRuleIndexes,
        parentGames,
        data,
        cardRules
      )
    );

    //only try again if there's twice as many options as the grid
    //this is to avoid the chance of infinite recursion
    if (
      newRule === undefined ||
      (cardContainsRule(newRule, cardRules) && cardRules.length > gridSize * 2)
    )
      return reRoll(index, gridSize, rrChange);

    const preppedRule = { ...newRule };

    if (preppedRule === undefined) return;

    setCardRules((oldRules) => {
      return [
        ...oldRules.slice(0, index),
        preppedRule,
        ...oldRules.slice(index + 1),
      ].map((rule) => {
        return { ...rule };
      });
    });

    setReRolls(reRolls + rrChange);

    if (socket !== null) {
      broadcastReRoll(oldSquare, newRule);
    }
  };

  const onSquareReRoll = (index: number) => {
    if (reRolls > 0) {
      reRoll(index, gridSize);
    }
  };

  const onGroupModalAction = (userName: string, groupToken: string) => {
    if (groupToken === "") {
      createGroup(userName);
    } else {
      groupToken = groupToken.toUpperCase();
      joinGroup(userName, groupToken);
    }
  };

  const onGroupClick = (e: any) => {
    setGroupOpen(!groupOpen);
  };

  return (
    <>
      {showConfetti && <Confetti />}
      <ToastContainer
        autoClose={
          window.innerWidth <= mobileBreak
            ? toastSettings.mobileTTL
            : toastSettings.desktopTTL
        }
        limit={
          window.innerWidth <= mobileBreak
            ? toastSettings.mobileLimit
            : toastSettings.desktopLimit
        }
        position={window.innerWidth <= mobileBreak ? "top-center" : "top-right"}
      />
      <div
        className={`${gameCardMobile} GameCard GameCard--${settings.grid[0]}-rows 
        GameCard--${settings.grid[1]}-cols ${columnsCss}`}
      >
        <GameCardHeader columns={settings.grid[1]} />

        {cardRules.map((rule, i) => {
          return (
            <RuleBox
              key={i}
              rule={rule}
              onClick={(e) => {
                if (!isFreeSquare(rule)) {
                  onRuleSquareClick(rule, i);

                  if (socket !== null) broadcastHighlight(i);
                }
              }}
            >
              {isFreeSquare(rule) ? (
                <FreeSquare
                  title={game.title}
                  subtitle="Drinking Bingo"
                  reRolls={reRolls}
                  onGroupClick={onGroupClick}
                  shakeSubTitle={showConfetti}
                  onCogClick={(e: any) => {
                    setSettingsOpen(true);
                  }}
                  groupToken={groupToken}
                />
              ) : (
                <RuleSquare
                  rule={rule}
                  key={i}
                  index={i}
                  reRolls={reRolls}
                  onReRoll={onSquareReRoll}
                />
              )}
            </RuleBox>
          );
        })}
      </div>
      <GroupModal
        open={groupOpen}
        onAction={onGroupModalAction}
        onClose={() => {
          setGroupOpen(false);
        }}
      />
      <GameCardSettingsModal
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
        onApply={onSettingsChange}
        game={game}
        games={games}
        settings={settings}
      />
    </>
  );
};

export default GameCard;
