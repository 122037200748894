import React, { useState, useRef, useEffect } from "react";
import { toast as doToast, ToastOptions } from "react-toastify";
import DataHelper from "../../DataHelper";
import { getCardRuleIndexByKey, onAnimationsEnabled } from "./helpers";

interface IProps {
  bingos: number;
  data: DataHelper;
  cardRules: ISquareRule[];
  onGroupHighlight: (ruleKey: string, force?: boolean) => void;
}
const useGroupPlay = ({
  bingos,
  data,
  cardRules,
  onGroupHighlight,
}: IProps) => {
  const [groupOpen, setGroupOpen] = useState<boolean>(false);
  const [groupToken, setGroupToken] = useState<string | null | undefined>(null);
  const [user, setUser] = useState<any>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [animationsEnabled, setAnimationsEnabled] = useState<boolean>(false);

  useEffect(() => {
    onAnimationsEnabled(() => {
      setAnimationsEnabled(true);
    });
  }, []);

  const toast = (msg: any, settings?: ToastOptions | undefined): void => {
    if (animationsEnabled) doToast(msg, settings);

    // if (!animationsEnabled) {
    //   setTimeout(() => {
    //     console.log(id);
    //     doToast.done(id);
    //     doToast.dismiss();
    //   }, 500);
    // }
  };

  const toastDefaults = {
    pauseOnFocusLoss: false,
  };

  const broadcastBingo = () => {
    socketSend("bingo", { user, groupToken });
  };

  useEffect(() => {
    if (socket) {
      broadcastBingo();
    }
  }, [bingos]);

  // interface User
  // {

  // }

  interface SocketMessage {
    user?: any;
    groupToken?: string;
    ruleKey?: string;
    oldRule?: string;
    newRule?: string;
  }

  const onGroupCreated = (data: SocketMessage) => {
    setUser(data.user);
    setGroupToken(data.groupToken);
    setGroupOpen(false);

    toast(
      `${data.user.data.userName} created group ${data.groupToken}`,
      toastDefaults
    );
  };

  const onSquareHighlightMessage = (msgData: SocketMessage) => {
    const rule = data.getRuleByKey(msgData.ruleKey);

    if (!rule) return;

    if (msgData.ruleKey) {
      onGroupHighlight(msgData.ruleKey, true);

      toast(
        <span>
          <strong>({rule.drinks})</strong> {rule.rule}
        </span>,
        toastDefaults
      );
    }
  };

  /**
   * This responds to the message from the server that this user joined the group
   * @param {Object} msgData
   */
  const onUserJoined = (msgData: SocketMessage) => {
    toast(`${msgData.user.data.userName} Joined the group`, toastDefaults);
    setGroupOpen(false);
    setUser(msgData.user);
    setGroupToken(msgData.groupToken);
  };

  const onNewGroupMember = (msgData: SocketMessage) => {
    toast(`${msgData.user.data.userName} Joined the group`, toastDefaults);
  };

  const socketSend = (action: string, data: any) => {
    console.log(action, socket);
    if (socket)
      socket.send(
        JSON.stringify({
          action,
          data,
        })
      );
  };

  /**
   * This is called after highlightRule, but fires before render loop, so highlighted isn't set yet on the square
   * @param {*} i
   */
  const broadcastHighlight = (i: number) => {
    const square = cardRules[i];

    const data = {
      userToken: user.token,
      groupToken,
      ruleKey: square.ruleKey,
    };

    const action = square.highlighted ? "un-highlight" : "highlight";
    socketSend(action, data);
  };

  const onGroupReRoll = (msgData: SocketMessage) => {
    const { userName } = msgData.user.data;
    const oldRule = data.getRuleByKey(msgData.oldRule);
    const newRule = data.getRuleByKey(msgData.newRule);

    console.log(msgData);
    toast(
      <>
        {userName} re-rolled{" "}
        <strong>
          <em>
            {oldRule.rule}
            <span> ({oldRule.drinks})</span>
          </em>
        </strong>{" "}
        to{" "}
        <strong>
          <em>
            {newRule.rule}
            <span> ({newRule.drinks})</span>
          </em>
        </strong>
      </>,
      toastDefaults
    );
  };

  const onBingo = (msg: SocketMessage) => {
    toast(`${msg.user.data.userName} GOT BINGO!`, {
      className: "bingo",
      ...toastDefaults,
    });
  };
  const messageHandlers: Record<string, any> = {
    "group-created": onGroupCreated,
    highlight: onSquareHighlightMessage,
    "user-joined": onUserJoined,
    "new-user-joined": onNewGroupMember,
    "re-rolled": onGroupReRoll,
    bingo: onBingo,
  };

  const broadcastReRoll = (oldRule: ISquareRule, newRule: ISquareRule) => {
    console.log(user);

    socketSend("re-roll", {
      oldRule: oldRule.ruleKey,
      newRule: newRule.ruleKey,
      groupToken,
      user,
    });
  };

  const onSocketMessage = (msg: MessageEvent<string>): void => {
    const { data, action } = JSON.parse(msg.data);

    const handler = messageHandlers[action];
    if (handler) {
      handler(data);
    }
  };

  const addMessageListener = (socketIn: WebSocket | null = socket) => {
    if (socketIn) {
      socketIn.removeEventListener("message", onSocketMessage);
      socketIn.addEventListener("message", onSocketMessage);
    }
  };

  const initSocket = (): WebSocket => {
    const socket = new WebSocket(
      window.location.host.indexOf("localhost") === -1
        ? "ws://162.252.83.130:8081"
        : "ws://localhost:8081"
    );

    addMessageListener(socket);

    return socket;
  };

  const createGroup = (userName: string): void => {
    const sock = initSocket();

    sock.addEventListener("open", (e) => {
      sock.send(
        JSON.stringify({
          action: "create-group",
          data: {
            userName,
          },
        })
      );
    });

    setSocket(sock);
  };

  const joinGroup = (userName: string, groupToken: string) => {
    const sock = initSocket();

    sock.addEventListener("open", (e) => {
      sock.send(
        JSON.stringify({
          action: "join-group",
          data: {
            user: { userName },
            groupToken,
          },
        })
      );
    });

    setSocket(sock);
  };

  return {
    groupOpen,
    setGroupOpen,
    groupToken,
    setGroupToken,
    user,
    setUser,
    socket,
    setSocket,
    createGroup,
    joinGroup,
    initSocket,
    broadcastHighlight,
    broadcastReRoll,
  };
};

export default useGroupPlay;
