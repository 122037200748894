export const getDefaultSettings = (parents: IGame[]): ISettings => {
  return {
    parents: parents.map((parent) => {
      return {
        key: parent.gameKey,
        ratio: 1,
      };
    }),
    grid: [5, 5],
  };
};

export const getSavedSettings = (key: string): ISettings | null => {
  let savedSettings: string | null | ISettings =
    window.localStorage.getItem(key);

  if (savedSettings !== null) {
    return JSON.parse(savedSettings) as ISettings;
  }

  return savedSettings;
};
