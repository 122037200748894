import React, { useEffect } from "react";
import { css } from "@emotion/css";
import { between } from "../../helpers";
import mug from "../../images/mug_confetti.png";

const confettiCss = css`
  width: 100vw;
  height: 100vw;
  z-index: 27;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  pointer-events: none;
  margin: 0;
  overflow: hidden;

  .confetti {
    position: absolute;
    width: 19px;
    height: 32px;
    background-image: url(${mug});
    bottom: -10%;
    box-shadow: 0 0 10px rgba(black, 0.75);
  }

  .wrapper {
    position: relative;
    min-height: 100vh;
  }
`;

interface IProps {
  show?: boolean;
  num?: number;
}
const Confetti = ({ show = false, num = 100 }: IProps) => {
  const showCss = css`
    background: ${show ? "red" : "transparent"};
    ${show ? "opacity:" : ""}
  `;

  const confetti = () => {
    const html = [];

    for (let i = 0; i <= 100; i++) {
      const w = between(1, 8);
      const l = between(1, 100);

      const pieceCss = css`
        left: ${l}%;
        transform: rotate(${between(0, 360)}deg);
        animation: drop-${i} ${between(3, 4)}s ${between(0, 1)}s infinite;
      `;

      html.push(
        <div key={`${i}-div`} className={`${pieceCss} confetti`}></div>
      );
      html.push(
        <style key={`${i}-style`}>
          {`@keyframes drop-${i} {
        100% {
          bottom: 110%;
          left: ${i + between(1, 15)}%;
        }`}
        </style>
      );
    }

    return html;
  };

  return (
    <div className={`Confetti ${confettiCss} ${showCss}`}>
      <div className="wrapper">{confetti()}</div>
    </div>
  );
};

// document.querySelector("head")!.innerHTML += `
//         <style data->
//           @keyframes drop-${i} {
//           100% {
//             bottom: 110%;
//             left: ${i + between(1, 15)}%;
//           }
//         </style>
//       `;

export default Confetti;
