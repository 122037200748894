import React from "react";
import mug from "../../images/mug.png";
interface RuleSquareProps {
  rule: ISquareRule;
  reRolls: number;
  onReRoll: (index: number) => void;
  index: number;
}

const RuleSquare = ({ rule, reRolls, onReRoll, index }: RuleSquareProps) => {
  return (
    <div className="RuleSquare">
      <div className={`rule-box__rule`}>{rule.rule}</div>
      <div className={`rule-box__drinks`}>{rule.drinks}</div>
      <div className={`rule-box__game-title`}>({rule.game!.title})</div>
      {reRolls > 0 && (
        <button
          className="rule-box__re-roll"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            onReRoll(index);
          }}
        >
          <i className="fa fa-refresh"></i>
        </button>
      )}
      <img src={mug} className="rule-box__mug" />
    </div>
  );
};

export default RuleSquare;
